// Body
$body-bg: #fff;

:root {
    --blank-lightblue-color: #E6EFEF;
    --blank-darkblue-color: #101F50;
    --blank-gold-color: #D69A28;
    --blank-black-color: #000;
}

$enable-rounded: false;

// Typography
$font-family-sans-serif: 'Montserrat', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
