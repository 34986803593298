// Fonts
//@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

/* Background colors */
.blank_light_blue_bg {
    background-color: var(--blank-lightblue-color);
}

.blank_dark_blue_bg {
    background-color: var(--blank-darkblue-color);
}

.blank_gold_bg {
    background-color: var(--blank-gold-color);
}

.blank_black_bg {
    background-color: var(--blank-black-color);
}

/* Foreground Colours */
.blank_light_blue_fg {
    color: var(--blank-lightblue-color);
}

.blank_dark_blue_fg {
    color: var(--blank-darkblue-color);
}

.blank_gold_fg {
    color: var(--blank-gold-color);
}

.blank_black_fg {
    color: var(--blank-black-color);
}

.btn-blank {
    color: $white;
    background-color: var(--blank-darkblue-color);
}




